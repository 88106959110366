
import { defineComponent } from 'vue';
import LayoutComponent from './components/common/LayoutComponent.vue';
export default defineComponent({
  components: {
    LayoutComponent
  },
  created() {
    console.log("App.vue created");
  },
  mounted() {
    console.log("App.vue mounted");
  },
  methods: {
    isSettingPage() {
      return this.$route.path != '/register'
        && this.$route.path != '/login'
        && this.$route.path != '/forget-password'
        && this.$route.path != '/recoverPassword'
    }
  }
});
