
import { defineComponent } from 'vue';

export default defineComponent({
    created() {
    },
    data() {
        return {
        }
    },
    mounted() {
        
    },
    methods: {
        
    }
});
