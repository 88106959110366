
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, Search, CommandColumn, Page, Group, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import agent, { masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { PropertyUser, Role, RoleGetResponse, SubscriptionGetResponse, User, UserGetResponse, UserResponse } from './User.model';
import _ from 'lodash';
import authService from '@/services/authService';
import { Subscription } from '../Subscription/Subscription.model';
import SubscriptionCommonComponent from '../Subscription/SubscriptionCommonComponent.vue';
import { API_KEY } from './constant';
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { DialogUtility } from '@syncfusion/ej2-vue-popups';


let _userData: PropertyUser = {} as PropertyUser;
let _userForm: User = {} as User;
let _subscriptionData: Subscription[] = [];
let _roleData: Role[] = [];
let Confirmation: any = undefined;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        'ejs-dropdownlist' : DropDownListComponent,
        SubscriptionCommonComponent,
        
    },
    provide: {
        grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group]
    },
    created() {
        agent.setBaseUrl(masterServiceBaseURL);

        this.gridLocalize();
    },
    data() {
        return {
            headers: {},
            listUserType: [
                { id: 0, name: "Select User Type" },
                { id: 1, name: "SystemAdmin" },
                { id: 2, name: "Customer" }
            ],
            listRole: _roleData,
            listSubscription: _subscriptionData,
            fieldsRole: { text: 'name', value: 'id' },
            fieldsSubscription: { text: 'organizationName', value: 'id' },
            fieldsUserType: { text: 'name', value: 'id' },
            data: _userData,
            form: _userForm,
            isUpdate: false,
            confirmTitle: "",
            confirmIsDelete: false,
            selectedState: null,
            subscriptionId: "",
            toolbar: ['Search'],
            searchOptions: { fields: ['FirstName', 'LastName', 'Email'], operator: 'contains', ignoreCase: true },
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageCount: 5,
                pageSize:50,
                skip:0,
                take:50
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
            ],
            locale: '',
        }
    },
    mounted() {
        this.dataStateChange(this.initState(this.pageSettings.skip,this.pageSettings.take));
        this.loadSubscriptions();
    },
    methods: {
        initState(skip:number=0,take:number): any {
            let state = {
                skip: skip,
                take: take
            };
            return state;
        },
        dataStateChange(state: any) {
            this.pageSettings.skip = state.skip;
            this.pageSettings.take = state.take;
            this.gridLocalize();
            this.loadUser(state);
        },
        async loadUser(state: any) {
            if (state.action) {
                delete state.action;
            }

            var subscriptionId = this.subscriptionId;
            const gridUser:any = this.$refs.gridUser;
            if(gridUser) gridUser.hideSpinner();

            const response = await agent.post<UserGetResponse>(API_KEY.GET_USER, { ...state, subscriptionId });

            if (response && response.result) {
                this.data = response.result;
            }
        },
        onUpsert(event: any) {
            event.preventDefault();

            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }
        },
        async onSave() {
            let post = { ...this.form };
            post.createdBy = authService.getUserEmail();

            const response = await agent.post<UserResponse>(API_KEY.POST, post);
            if (response && response.result) {
                toasterService.success(this.$t("msgSave"));
                this.loadUser(this.initState(this.pageSettings.skip,this.pageSettings.take));
                this.resetUser();
                this.close();
            }
        },
        async onEdit() {
            let put = { ...this.form };
            put.modifiedBy = authService.getUserEmail();

            const response = await agent.put<UserResponse>(API_KEY.PUT, put);
            if (response && response.result) {
                toasterService.success(this.$t("msgUpdate"));
                this.loadUser(this.initState(this.pageSettings.skip,this.pageSettings.take));
                this.resetUser();
                this.close();
            }
        },
        async onDelete(id:number) {
            const response = await agent.delete<UserResponse>(API_KEY.DELETE(id));
            if (response.result) {
                toasterService.success(this.$t("msgDelete"));
                this.loadUser(this.initState(this.pageSettings.skip,this.pageSettings.take));
                this.resetUser();
            }
        },
        resetUser(isNew: boolean = false) {
            this.form = {
                subscriptionId: "",
                roleId: 0,
            } as User;
            this.isUpdate = false;
            this.form.isActive = true;
            if (isNew) {
                this.form.subscriptionId = this.subscriptionId;
                this.loadRoles(this.form.subscriptionId);
            }
        },
        async loadSubscriptions() {
            const response = await agent.post<SubscriptionGetResponse>(API_KEY.GET_SUBSCRIPTION, {});
            if (response && response.result) {
                this.listSubscription = [{id:"",organizationName:this.$t('msgSelectCustomer')} as Subscription,...response.result.result];
            }
        },
        onSelectCustomer(event:any){
            if(event && event.itemData){
                const id = event.itemData.id;
                this.loadRoles(id);
            }
        },
        async loadRoles(subscriptionId: string) {
            if(_.isEmpty(subscriptionId)) {
                this.listRole = [];
                return;
            }
            const response = await agent.post<RoleGetResponse>(API_KEY.GET_ROLE, { subscriptionId: subscriptionId });
            if (response && response.result) {
                this.listRole = [{id:0,name:this.$t('msgSelectRole')},...response.result.result];
            }
        },
        onChangeUserSubscription(event: any) {
            this.loadRoles(event.target.value);
        },
        onSubscriptionChange(subscriptionId: string) {
            this.subscriptionId = subscriptionId;

            if (subscriptionId.trim().toLowerCase().indexOf("all") > -1) {
                this.subscriptionId = "";
            }

            this.loadUser(this.initState(this.pageSettings.skip,this.pageSettings.take));
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: User }) {
            this.isUpdate = false;
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.form.id = args.rowData.id;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureWantToDelete"),
                            okButton: {
                            text: this.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDelete(app.form.id);
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    case 'Edit':
                        this.onEditInit(args.rowData);
                        break;
                    default: break;
                }
            }
        },
        async onEditInit(form: User) {
            const response = await agent.post<RoleGetResponse>(API_KEY.GET_ROLE, { subscriptionId: form.subscriptionId });
            if (response && response.result) {
                this.listRole = [{id:0,name:this.$t('msgSelectRole')},...response.result.result];
                setTimeout(()=>{
                    this.form = { ...form };
                    this.isUpdate = true;
                    modal.Open('userModal');
                },200)
            }
        },
        isHost() {
            return authService.isHost();
        },
        open(isNew: boolean = false) {
            this.resetUser(isNew);
            modal.Open('userModal');
        },
        close() {
            this.resetUser();
            modal.Close('userModal');
        },
    }
});
