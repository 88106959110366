const requestCounterKey: string = 'requestCounterKey';

const preLoader = {
    counter:0 as number,
    reqCounterIncrement() {
        this.counter++;
        
    },
    reqCounterDecrement(){

        if(this.counter>0) --this.counter;

    },
    show(){
        try{
             
            const page_loader = document.getElementById("page_loader")!;
            const spinner = document.getElementById("page_loader_spinner")!;
            if(!page_loader || !spinner) return;
            page_loader.style.display="block";
            spinner.style.display="block";
        }catch(e){
            console.log(e);
        }
    },
    hide(){
        try{
           
            if(this.counter>0){
             return;
            }
            const page_loader = document.getElementById("page_loader")!;
            const spinner = document.getElementById("page_loader_spinner")!;
            if(!page_loader || !spinner) return;
            if(page_loader) page_loader.style.display="none";
            if(spinner) spinner.style.display="none";
        }catch(e){
            console.log(e);
        }
    }
}

export default preLoader;