export const API_KEY = {
    GET_PAGES:"v1/page/getpage",
    GET_MODULES:"v1/module/getmodule",
    GET_SUBSCRIPTIONS:"v1/subscription/getsubscription",
    GET_PARENTS:"v1/page/parents",
    POST:"v1/page",
    PUT:"v1/page",
    DELETE:(id:number)=>`v1/page/${id}`
}

export const TABLE_SETTINGS = {
    SEARCH_OPTIONS:{
        fields: [
            'Title',
            'Url',
            'Icon'
        ], 
        operator: 'contains', 
        ignoreCase: true 
    },
    PAGE_SETTINGS:{
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        pageCount: 5,
        state:{
            skip:0,
            take:50,
        } as any
    },
    COMMANDS:[
        { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
        { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
    ],
    TOOLBARS:[ 'Search'],
}
