import type { NavigationGuard } from "vue-router";
import authService from "./authService";
import _ from "lodash";
import { useUserStore } from "@/store/useUserStore";

export const authGuard: NavigationGuard = async (to, from, next) => {
  const token = authService.getToken();

  let pages: any[] = [];
  const userStore = useUserStore();
  const pagesParent = userStore.userPages;
  if (pagesParent) {
    const pagesChilds = _.flatMap(pagesParent, ({ childs }) =>
      childs.map((ele) => ({ ...ele }))
    );
    pages = [...pagesParent, ...pagesChilds];
  }

  if (!token) {
    next("/login");
  }

  if (
    (pages && _.map(pages, (i) => i.pageUrl).includes(to.path)) ||
    to.path == "/home"
  ) {
    next();
  }

  next();
};
