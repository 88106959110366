import enMessages from '@/locales/en';
import seMessages from '@/locales/se';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    legacy: false,
    locale: 'en', // default locale
    messages: {
      en: enMessages,
      se: seMessages,
    },
  });

export default i18n;


