const se = {
  //Common
  update: "Uppdatering",
  save: "Spara",
  remove: "Avlägsna",
  addNew: "Lägg till ny",
  add: "Lägg till",
  actions: "Handlingar",
  close: "Stänga",
  all: "Allt",
  edit: "Redigera",
  delete: "Radera",
  selectAll: "Välj alla",
  swedish: "Svenska",
  english: "Engelsk",
  yes: "Ja",
  no: "Nej",
  clear: "Klar",
  new: "Ny",
  modules: "Modules",
  msgSureWantToDelete: "Är du säker på att du vill radera?",
  msgSureWantToMapAllCompany:
    "Är du säker på att du vill kartlägga alla företag?",
  msgSureWantToMap: "Är du säker på att du vill kartlägga?",
  msgAllCompany: "Alla företag",
  msgOnlyThisCompany: "Bara detta företag",
  msgSureWantToDeleteRole:
    "Rollen används av användare. Är du säker på att du vill radera?",
  msgSave: "Posten har sparats!",
  msgUpdate: "Posten har uppdaterats framgångsrikt!",
  msgDelete: "Radera information framgångsrikt!",
  msgRecoveryKeyNotFound: "Ogiltig återställningsnyckel!",
  deleteConfiramtion: "Ta bort bekräftelse",
  mapConfirmation: "Kartbekräftelse",
  host: "Värd",
  logOut: "Logga ut",
  myProfile: "Min profil",
  keepSingle: "Behåll enstaka",
  msgRecoverPasswordSuccessfully: "Återställ lösenord framgångsrikt!",
  cancel: "Annullera",
  signOut: "Logga ut",
  msgConfirmation: "Bekräftelse",
  sourceConfirmation: "Källbekräftelse",
  msgSureWantToChangeSource: "Är du säker på att du vill byta källa?",
  msgThisCompany: "Ansök till detta företag",
  msgApplyAllCompany: "Ansök till alla företag",

  //Home
  home: "Hem",

  //Change Password
  changePassword: "Ändra lösenord",
  oldPassword: "Gammalt lösenord",
  newPassword: "nytt lösenord",
  confirmPassword: "Bekräfta lösenord",
  change: "Förändra",

  //Module
  module: "Modul",
  name: "Namn",
  displayName: "Visningsnamn",
  active: "Aktiva",
  displayNameRequired: "Visningsnamn krävs!",
  selectModule: "Välj en modul",

  //Customer
  customer: "Kund",
  organization: "Organisation",
  server: "Server",
  database: "Databas",
  subscriber: "Abonnent",
  type: "Typ",
  first: "Först",
  last: "Sista",
  email: "E-post",
  password: "Lösenord",
  retype: "Skriva om",
  use: "Använda sig av",
  internal: "Inre",
  external: "Extern",
  user: "Användare",
  test: "Testa",
  connection: "Förbindelse",
  register: "Registrera",
  loading: "Läser in",
  subscriptionDeleteConfirm:
    "Radera även databas relaterad till denna prenumeration",
  msgUnableHostDelete: "Det går inte att ta bort värd!",
  msgOrganizationNameNotGood: "Ange ett giltigt organisationsnamn!",
  msgPasswordConfirmPassword:
    "Lösenord och bekräftelselösenord stämmer inte överens!",
  msgSelectModuleAtLeast: "Välj minst en modul!",
  msgSelectDatabase: "Välj databas!",
  msgGiveServer: "Vänligen ange servernamn!",
  msgGiveDBUserName: "Vänligen ange databasens användarnamn!",
  msgGiveDBUserPassword: "Vänligen ge databasens användarlösenord!",
  msgSelectCustomer: "Välj kund",
  msgModuleError: "Vänligen korrigera modulfel",
  fetchDatabases: "Hämta databaser",
  msgCustomerNumberExists: "Kundnummer finns redan!",
  msgRenameSchema:
    "När du har bytt namn på modulschemat kommer du inte längre att kunna använda det gamla schemat.",

  //Page
  page: "Sida",
  title: "titel",
  url: "URL",
  icon: "Ikon",
  parent: "Förälder",
  ordinal: "ordinarie",
  msgSelectModule: "Välj modul",
  msgSelectSubscriberType: "Vänligen välj prenumeranttyp",
  msgSelectParent: "Välj förälder",
  settings: "inställningar",

  //Permission
  permission: "Lov",
  role: "Roll",
  msgGiveRole: "Vänligen ge roll",
  msgSelectOnePageAtLeast: "Välj minst en sida",

  //User
  subscription: "Prenumeration",
  msgSelectRole: "Välj roll",
  msgSelectSubscription: "Vänligen välj prenumeration",

  //Cost Center
  costCenter: "Kostnadscenter",
  code: "Koda",
  note: "Notera",

  //Cost Center Permission
  scope: "Omfattning",
  scopes: "Omfattningar",
  fullName: "Fullständiga namn",
  msgSelectPermissionScope: "Välj behörighetsomfång",
  msgSelectUser: "Välj användare",
  msgSelectCostCenterAtLeast: "Välj minst ett kostnadsställe",
  allPermissionScope: "Alla behörighetsomfång",

  //Result Sheet (Financial Reporting)
  financial: "Finansiell",
  reporting: "Rapportering",
  account: "Konto",
  mapping: "Kartläggning",
  balance: "Balans",
  sheet: "Ark",
  design: "Design",
  back: "Tillbaka",
  range: "Räckvidd",
  manage: "Hantera",
  select: "Välj",
  default: "Standard",
  start: "Start",
  end: "Slutet",
  specific: "Specifik",
  row: "Rad",
  costCenterLevel: "Kostnadsställenivå",
  msgSureDeleteHeaderSubHeader:
    "Är du säker på att ta bort den här rubriken med alla underrubriker?",
  msgSureDeleteSubHeader: "Är du säker på att ta bort dessa underrubriker?",
  msgSureDeleteResultSheet: "Är du säker på att du vill ta bort resultatblad",
  msgSureDuplicateResultSheet:
    "Är du säker på att du vill duplicera resultatark",
  msgSureDeleteBalanceSheet:
    "Är du säker på att du vill ta bort balansräkningen",
  msgSureDuplicateBalanceSheet: "Are you sure want to duplicate balance sheet",
  msgDefaultResultSheetRequiredForCreateForecast:
    "Standardresultatblad som krävs för att skapa en prognos",

  //Account Group
  declaration: "Deklaration",
  group: "Grupp",
  sign: "Skylt",
  msgSelectAccountGroup: "Välj kontogrupp",
  msgSelectSource: "Välj källa",
  msgSelectCostCenter: "Välj kostnadsställe",
  msgSelectCostCenterLevel: "Välj kostnadsställenivå",
  addSpecificSubHeader: "Lägg till specifik rubrik",
  accountGroupName: "Konto grupp namn",
  accountGroups: "Kontogrupper",
  accountGroup: "Kontogrupp",
  isInternal: "Is Internal",
  isRevenue: "Is Revenue",
  msgAllAccountGroup: "Alla kontogrupper",

  //Source
  source: "Källa",
  msgGiveSource: "Vänligen ange källa",

  //Result Sheet
  resultSheet: "Resultatblad",
  manageResultSheet: "Hantera resultatblad",
  addNewRow: "Lägg till ny rad",
  header: "Rubrik",
  subHeader: "Underrubrik",
  bold: "Djärv",
  extraBold: "Extra fet",
  showSubHeader: "Visa underrubrik",
  showHeader: "Visa rubrik",
  isPercentage: "är Procent",
  resultSheetDesignRow: "Resultatblad Designrad",
  rowName: "Radens namn",
  msgResultSheetNotSelected: "Inget resultatblad valt",
  msgSelectResultSheet: "Välj resultatblad",
  msgSelectHeader: "Välj rubrik",
  msgSelectSubHeader: "Välj underrubrik",
  msgSelectSpecificSubHeader: "Vänligen välj specifik underrubrik",
  msgGiveDefaultSubHeader: "Vänligen ange standard underrubrik",
  msgSelectDefaultSubHeader: "Vänligen välj standard underrubrik",
  msgGiveSign: "Vänligen ge tecken",
  isProfitLevel: "Är vinstnivå",
  isProfitLevelPercentage: "Är vinstnivå(%)",
  isDefault: "Huvudsaklig",

  //Balance Sheet
  msgBalanceSheetNotSelected: "Ingen balansräkning vald",
  balanceSheetDesignRow: "Balansräkningsdesignrad",
  manageBalanceSheet: "Hantera balansräkning",
  msgSelectBalanceSheet: "Välj balansräkning",

  //Balance Sheet Mapping
  msgSureDeleteBalanceSheetMapping:
    "Är du säker på att du vill ta bort balansräkningsmapping?",

  //Fortnox

  //Company
  msgSureAllDataWithCompany:
    "All data relaterade till detta företag kommer att raderas. Är du säker på att du vill radera?",
  creationTime: "Skapande tid",
  refreshTokenExpiryDate: "RefreshTokens utgångsdatum",
  lastSyncedDate: "Senaste synkroniserade datum",
  msgSelectCompany: "Vänligen välj företag",
  scopeChangeApplied: "Omfattningsändring tillämpad",
  orderOrCustomerInvoices: "Order or Kundfaktura",
  bookkipingOrCustomerInvoices: "Bokföring or Kundfaktura",
  bookkipingOrOrderOrCustomerInvoices: "Bokföring or Order or Kundfaktura",
  any: "Några",
  bookkiping: "Bokföring",
  order: "Order",
  salary: "Lön",
  facilityRegister: "Anläggningsregister",
  share: "Share",

  //Customer
  companyId: "Företags ID",
  address1: "Adress 1",
  address2: "Adress 2",
  city: "Stad",
  customerNumber: "Kundnummer",
  organisationNumber: "Organisationsnummer",
  phone: "Telefon",
  zipCode: "Postnummer",
  msgSelectResponsible: "Skriv välj ansvarig..",
  customerResponsible: "Kundansvarig",
  manageCustomerGroup: "Hantera kundgrupp",
  backCustomer: "Tillbaka Kund",
  customerGroup: "Kundgrupp",

  //Account
  companyName: "Företagsnamn",
  description: "Beskrivning",
  number: "siffra",
  sRU: "SRU",
  year: "År",
  vATCode: "Moms kod",
  balanceBroughtForward: "Balans förs fram",
  balanceCarriedForward: "Balans förs framåt",
  syncDate: "Synkronisera datum",
  rangeStart: "Räckvidd Start",
  rangeEnd: "Intervall slut",
  find: "Hitta",
  msgAllYear: "Hela året",

  //Financial Year
  id: "Id",
  fromDate: "Från datum",
  toDate: "Hittills",
  accountCharts: "Kontodiagram",
  accountingMethod: "Redovisningsmetod",

  //Project
  projectNumber: "Projektnummer",
  startDate: "Start datum",
  endDate: "Slutdatum",
  status: "Status",
  isDeleted: "är Radera",

  //SyncLog
  masterLogId: "Huvudlogg-ID",
  requestedAt: "Begärd kl",
  requestedBy: "Efterfrågad av",
  startedAt: "Började kl",
  runStatus: "kör Status",
  hasError: "Har fel",
  errorMessage: "Felmeddelande",
  isFullSync: "Full synk",
  jobRunId: "Job Run ID",
  tryCount: "Försök med Count",
  syncLogDetails: "Synkroniseringsloggdetaljer",
  logId: "Logg-ID",
  completedAt: "Avslutad kl",
  entity: "Entitet",
  copy: "kopia",
  completedWithErrors: "Slutförd med fel",
  completed: "Fullbordad",
  pending: "Avvaktan",
  failed: "Misslyckades",
  running: "Löpning",

  //CompanySettings
  organizationNumber: "Organisationsnummer",
  address: "Adress",
  bG: "BG",
  bIC: "BIC",
  branchCode: "Gren-kod",
  contactFirstName: "Kontakta Förnamn",
  contactLastName: "Kontaktens efternamn",
  country: "Land",
  countryCode: "Landskod",
  databaseNumber: "Databasnummer",
  domicile: "Hemvist",
  fax: "",
  iBAN: "IBAN",
  pG: "PG",
  phone1: "Telefon1",
  phone2: "Telefon 2",
  taxEnabled: "Skatt aktiverad",
  vATNumber: "Momsregistreringsnummer",
  visitAddress: "Besöksadress",
  visitCity: "Besök City",
  visitCountry: "Besök Country",
  visitCountryCode: "Besök landskod",
  visitName: "Besök Namn",
  visitZipCode: "Besök postnummer",
  website: "Hemsida",

  //Invoice
  booked: "Bokad",
  cancelled: "Inställt",
  currency: "Valuta",
  currencyRate: "Växlingskurs",
  currencyUnit: "Valutaenhet",
  customerName: "Köparens namn",
  documentNumber: "dokumentnummer",
  dueDate: "Förfallodatum",
  externalInvoiceReference1: "Extern fakturareferens1",
  externalInvoiceReference2: "Extern fakturareferens2",
  invoiceDate: "Fakturadatum",
  invoiceType: "Fakturatyp",
  noxFinans: "Nox Finans",
  oCR: "OCR",
  voucherNumber: "kupongnummer",
  voucherSeries: "Verifikationsserie",
  voucherYear: "Verifikationsår",
  wayOfDelivery: "Leveranssätt",
  termsOfPayment: "Betalningsvillkor",
  sent: "Skickat",
  total: "Total",
  finalPayDate: "Sista betalningsdatum",
  language: "Språk",
  lastRemindDate: "Last Remind Date",
  net: "Netto",
  notCompleted: "Ej genomförd",
  offerReference: "Erbjudande referens",
  orderReference: "Orderreferens",
  ourReference: "Våran referens",
  paymentWay: "Betalningssätt",
  priceList: "Betalningssätt",
  printTemplate: "Skriv ut mall",
  remarks: "Anmärkningar",
  reminders: "Påminnelser",
  roundOff: "Runda av",
  taxReduction: "Skattereduktion",
  termsOfDelivery: "Leveransvillkor",
  totalVAT: "Total moms",
  vATIncluded: "Moms ingår",
  yourOrderNumber: "Ditt beställningsnummer",
  yourReference: "Er referens",
  taxReductionType: "Typ av skattereduktion",
  isSyncRows: "Synkronisera rader",
  invoiceDetails: "Fakturadetaljer",

  //Invoice Accural
  invoiceNumber: "Fakturanummer",
  period: "Period",
  uRL: "URL",

  //Invoice Payment
  amount: "Belopp",
  writeOffExist: "Avskriv existerar",
  paymentDate: "Betalningsdag",

  //Supplier Invoice
  administrationFee: "Administrationsavgift",
  comments: "Kommentarer",
  credit: "Kreditera",
  creditReference: "Kreditreferens",
  disablePaymentFile: "Inaktivera betalningsfil",
  externalInvoiceNumber: "Externt fakturanummer",
  externalInvoiceSeries: "Extern fakturaserie",
  freight: "Frakt",
  givenNumber: "Givet nummer",
  paymentPending: "Betalning behandlas",
  roundOffValue: "Runda av värde",
  supplierNumber: "Leverantörsnummer",
  supplierName: "Leverantörsnamn",
  vAT: "MOMS",
  vATType: "momstyp",
  salesType: "Försäljningstyp",
  creditCurrency: "Kredit Valuta",
  vat: "MOMS",
  ocr: "OCR",
  isSyncedRow: "Är synkroniserad rad",
  isPaymentPending: "Väntar betalning",
  vatType: "Typ av moms",
  supplierInvoiceDetails: "Leverantörsfakturadetaljer",
  articleNumber: "Artikelnummer",
  itemDescription: "Artikelbeskrivning",
  accountDescription: "Kontobeskrivning",
  debitCurrency: "Debit Valuta",
  transactionInformation: "Transaktionsinformation",
  debit: "Debitera",
  price: "Pris",
  quantity: "Kvantitet",
  stockPointCode: "Lagerpunkt kod",
  stockLocationCode: "Lagerplats kod",
  rowIndex: "Radindex",
  rowNumber: "Radnummer",
  removed: "Borttagen",
  supplierGroup: "Leverantörsgrupp",
  manageSupplierGroup: "Hantera leverantörsgrupp",
  backSupplier: "Tillbaka Leverantör",

  //Supplier Invoice File Connection
  fileId: "Fil-ID",
  supplierInvoiceNumber: "Leverantörsfakturanummer",

  //Voucher
  transactionDate: "Transaktions Datum",
  referenceNumber: "Referensnummer",
  referenceType: "Referenstyp",
  approvalState: "Godkännande stat",

  //Menu
  costCenterPermission: "Kostnadsställetillstånd",
  financialReporting: "Ekonomisk rapportering",
  "p&lMapping": "P&L-kartläggning",
  "p&lDesign": "P&L Design",
  "p&lHeader": "P&L Header",
  balanceSheetDesign: "Balansräkningsdesign",
  balanceSheetMapping: "Balansräkningsmatning",
  fortnox: "Fortnox",
  company: "Företag",
  accountChart: "Kontodiagram",
  financialYear: "Budgetår",
  project: "Projekt",
  syncLog: "Synkroniseringslogg",
  companySettings: "Företagsinställningar",
  invoice: "Faktura",
  invoiceAccural: "Fakturaperiodisering",
  invoicePayment: "Faktura betalning",
  supplierInvoice: "Leverantörsfaktura",
  supplierInvoiceFileConnection: "Leverantörsfakturafilanslutning",
  voucher: "Kupong",
  absenceTransaction: "Frånvarotransaktion",
  articleFileConnection: "Artikelfilanslutning",
  assetFileConnection: "Tillgångsfilanslutning",
  assetType: "Tillgångstyp",
  asset: "Tillgång",
  attendanceTransaction: "Närvarotransaktion",
  contractAccrual: "Kontraktperiodisering",
  contractTemplate: "Kontraktmall",
  contract: "Kontrakt",
  lockedPeriod: "Låst period",
  offer: "Erbjudande",
  salaryTransaction: "Lönetransaktion",
  scheduleTime: "Tidsplanering",
  supplierInvoiceAccrual: "Leverantörsfakturaperiodisering",
  supplierInvoiceExternalUrlConnection:
    "Leverantörsfaktura extern URL-anslutning",
  supplierInvoicePayment: "Leverantörsfakturabetalning",

  // VoucherFIleConnection
  voucherFileConnection: "Kupongfilanslutning",
  voucherDescription: "Verifikationsbeskrivning",
  doSync: "Gör Sync",
  enableSyncing: "Aktivera synkronisering",

  // "Account Mapping" :"Konto Kartläggning",
  // "Back Account Mapping" :"Tillbaka Kontomappning",
  // "Back Balance Sheet Mapping" :"Bakre balansräkningsmatning",
  //
  // "Balance Sheet":"Balansräkning",
  // "Balance Sheet Mapping":"Balansräkning Kartläggning",
  // "Balance Sheet Design Row":"",
  //
  // "Manage Balance Sheet":"Hantera balansräkning",
  // "Manage Source":"Hantera källa",
  // "Manage Account Groups":"Hantera kontogrupper",
  // "Manage Account Range":"Hantera kontointervall",
  // "Add Account Range":"Lägg till kontointervall",
  // "Swedish Name":"Svenskt namn",
  // "Declaration":"Deklaration",

  // "Account Group":"Kontogrupp",
  // "English Name":"Engelskt namn",

  // "Design":"Design",
  //
  // "Select Result Sheet":"Välj resultatblad",
  // "Select Balance Sheet":"Välj Balansräkning",
  // "Select Balance Sheet Mapping":"Välj Balansräkningsmappning",
  //
  //
  // ,
  //
  //
  // "Select":"Välj",
  // "Source":"Källa",
  // ,
  // "Start":"Start",
  // "End":"Slutet",
  // "Default Header":"Standardhuvud",
  // "Specific Sub Header":"Specifik underrubrik",
  // "Range":"Räckvidd",
  // "Add Specific Header":"Lägg till specifik rubrik",
  // "Close":"Stänga",
  // "Balance Sub Header":"Balans underrubrik",
  // "Save information successfully":"Spara information framgångsrikt",
  // "Update information successfully":"Uppdatering information framgångsrikt",
  // "Delete information successfully":"Radera information framgångsrikt",
  // "All (*) marks are required":"Alla (*) märken krävs",
  // "DatabaseNameErrorMessage": "Databasnamnet måste vara mellan 1 och 128 tecken.",
  // "User":"Användare",
  // "Cost Center Permission":"Kostnadsställetillstånd",
  // "Fortnox":"Fortnox",
  // "Company":"Företag",
  // "Financial Reporting":"Ekonomisk rapportering",
  // "P&L Account Mapping":"P&L kontomappning",
  // "P&L Design":"P&L Design",
  // "Balance Sheet Design":"Balansräkningsdesign"

  //Page Build
  pageBuilder: "Sidbyggare",
  pageBuild: "Sidbyggnad",
  pageManage: "Hantera sidor",
  databaseTableName: "Databas tabellnamn",
  buildForm: "Bygg formulär",
  definition: "Definition",
  input: "Ingång",
  selectFieldType: "Välj fälttyp",
  fieldType: "Fälttyp",
  dropdownRef: "Dropdown Ref",
  selectSchema: "Välj schema",
  selectTable: "Välj tabell",
  selectColumnId: "Välj kolumn-ID",
  selectColumnName: "Välj kolumnnamn",
  hasDatabaseSource: "Har databaskälla",
  dropdownData: "Dropdown-data",
  selectColumn: "Välj kolumn",
  radioData: "Radio Data",
  checkboxRef: "Kryssruta Ref",
  checkboxData: "Kryssruta Data",
  defaultDate: "Standarddatum",
  databaseType: "Databastyp",
  decimalPlace: "Decimalplats",
  selectDatabaseType: "Välj databastyp",
  size: "Storlek",
  placeHolder: "Platsmarkör",
  isMax: "Är Max",
  databaseName: "Databasnamn",
  isRequired: "Krävs",

  //Page Manage
  importExcel: "Importera(.xls/.csv)",
  selectPage: "Välj sida",
  ok: "OK",
  root: "Rot",

  pleaseFillAllRequiredFields: "Fyll i alla obligatoriska fält",
  dataImportedSuccessfully: "Data importerad framgångsrikt",

  //Suppliers
  suppliers: "Leverantörer",
  companyname: "Företagsnamn",
  bankAccountNumber: "Bankkontonummer",
  costcenter: "Kostnadscenter",
  preDefinedAccount: "Fördefinierat konto",
  costCenterName: "Kostnadsställets namn",
  costCenterNumber: "Kostnadsställets nummer",

  //Data Sync Configuration
  dataSyncConfiguration: "Datasynkroniseringskonfiguration",
  featureName: "Funktionens namn",
  isSyncFromFortnox: "Är Sync från Fortnox",
  syncFromFortnox: "Synk från Fortnox",
  log: "Logga",
  organizationName: "Organisationsnamn",
  msgSelectFeature: "Vänligen välj funktion",
  isEnableSync: "Är aktivera synkronisering",
  allSync: "Alla synkronisering",

  //Property
  property: "Egendom",
  selectProperty: "Välj egendom",
  values: "Värden",
  msgGivePropertyName: "Vänligen ge egenskapsnamn",
  msgGivePropertyValue: "Vänligen ge egenskapsvärde",

  units: "Enheter",
  date: "Datum",
  employeeId: "Anställnings-ID",
  expense: "Bekostnad",
  salaryCode: "Lönebalken",
  salaryRow: "Lönerad",
  textRow: "Textrad",
  deliveryDate: "Leveransdatum",
  orderDate: "Orderdatum",
  orderType: "Ordertyp",
  personelType: "Personlig typ",
  personalIdentityNumber: "Personnummer",
  nonRecurringTax: "Engångsskatt",
  monthlySalary: "Månadslön",
  jobTitle: "Jobbtitel",
  inActive: "Inaktiv",
  foraType: "Fora typ",
  fromQuantity: "Från kvantitet",
  hours: "Timmar",
  scheduleId: "Schema-ID",
  externalUrlConnection: "Extern URL-anslutning",
  employmentForm: "Anställningsblankett",
  employmentDate: "Anställningsdatum",
  employedTo: "Anställd till",
  clearingNo: "Clearing nr",
  bankAccountNo: "Bankkontonummer",
  averageWeeklyHours: "Genomsnittligt veckotimmar",
  averageHourlyWage: "Genomsnittlig timlön",
  autoNonRecurringTax: "Automatisk ej återkommande skatt",

  //Power Office
  powerOffice: "Power Office",
  applicationKey: "Applikationsnyckel",
  clientKey: "Klientnyckel",
  subscriptionKey: "Prenumerationsnyckel",
  shouldSync: "Ska Synkroniseras",
  notation: "Notation",
  accountNo: "Kontonummer",
  createdDate: "Skapad Datum",
  lastChangedDate: "Ändrad Datum",
  currencyCode: "Valutakod",
  postingDate: "Bokföringsdatum",
  voucherDate: "Verifikationsdatum",
  voucherNo: "Verifikationsnummer",
  accountTransaction: "Kontotransaktion",
  client: "Klient",
  clientContact: "Klientkontakt",
  contactGroup: "Kontaktgrupp",
  contactPerson: "Kontaktperson",
  dimension: "Dimensionera",
  dimensionSetting: "Dimensionsinställning",
  customerSubledger: "Kundreskontra",
  supplier: "Leverantör",
  supplierSubledger: "Leverantörsreskontra",
  clientName: "Klientnamn",
  emailAddress: "E-postadress",
  yearOfBirth: "Födelseår",
  legalName: "Juridiskt Namn",
  contactCreatedDate: "Kontakt Skapad Datum",
  customerCreatedDate: "Kund Skapad Datum",
  customerSince: "Kund Sedan",
  invoiceEmailAddress: "Faktura E-post",
  phoneNumber: "Telefonnummer",
  paymentTermId: "Betalningsvillkor ID",
  currencyAmount: "Valutabelopp",
  customerAccountNo: "Kundkontonummer",
  supplierAccountNo: "Leverantörskontonr",
  invoiceNo: "Fakturanummer",
  projectCode: "Projektnummer",
  projectId: "Projekt ID",
  voucherId: "Verifikation ID",
  createdFromImportJournalId: "Importera journal-ID",
  externalImportReference: "Extern Importreferens",
  customerSubLedger: "Kundens reskontra",
  journalVoucher: "Journalkupong",
  departmentCode: "Avdelningskod",
  generalLedgerAccount: "Huvudbokskonto",

  //Auth Setting
  authSetting: "Autentiseringsinställning",
  clientId: "Klient-ID",
  clientSecret: "Klient",

  //CostCenter Group
  costCenterGroup: "Kostnadsställegrupp",
  manageCostCenterGroup: "Hantera kostnadsställegrupp",
  backCostCenter: "Tillbaka kostnadsställe",
  msgSelectCostCenterGroup: "Välj kostnadsställegrupp",

  isActive: "Är aktiv",

  //Budget/Forecast
  budgetForecast: "Budget/Prognos",
  msgSelectYear: "Välj år",
  msgSelectVersion: "Välj version",
  version: "Version",
  actualStart: "Faktisk start",
  actualEnd: "Faktiskt slut",
  forecastStart: "Prognos Start",
  forecastEnd: "Prognos slut",
  clearActual: "Rensa faktiska",
  setAsCurrentDefaultForecast: "Ställ in som aktuell standard",
  forecast: "Prognos",
  forecastVersion: "Prognos version",
  msgSelectProject: "Välj projekt",
  excelExport: "Excel Exportera",
  msgSelectCustomerResponsible: "Vänligen välj kundansvarig",
  allProject: "Alla projekt",
  allCustomer: "Alla kunder",
  msgNoRecordsToDisplay: "Inga poster att visa",
  ccGroup: "C.C Grupp",
  aGroup: "A. Grupp",
  responsible: "kundansvarig",
  current: "Aktuell",
  customerForecast: "Prognos för kunder",
  enterTextHere: "Skriv in text här (max:500)",
  submit: "Inlämna",
  comment: "Kommentar",
  msgSelectForecastVersion: "Vänligen välj prognosversion",
  copyFrom: "Kopiera från",
  msgChangesConfirmation: "Bekräftelse av ändringar",
  msgLeaveForecastChanges:
    "Varning: Du har osparade ändringar. Är du säker på att du vill fortsätta?",
  isBudget: "Är budget",
  msgForecastVersionClose:
    "Faktiska värden för denna version kommer att tas bort från prognosen och de faktiska värdena kommer att uppdateras med faktiska intäktsdata. Vill du fortsätta?",
  msgForecastVersionCloseConfirmation: "Bekräftelse av prognosversion",
  projectForecast: "Projekt prognos",
  //Employee
  employee: "Anställd",
  firstName: "Förnamn",
  hourlyPay: "Timlön",
  lastName: "Efternamn",
  postCode: "Postnummer",
  salaryForm: "Löneform",
  taxAllowance: "Skatteavdrag",
  taxColumn: "Skattekolumn",
  taxTable: "Skattetabell",

  //Financial Year
  msgToDateShouldBeGreater: "Till datum ska vara större än från datum",

  //password validator
  msgEnterAPassword: "Ange ett lösenord.",
  msgInvalidPassword:
    "Lösenordet kräver minst en siffra, en liten bokstav, en stor bokstav och minst sex tecken.",

  //App-Log
  logType: "Typ av logg",
  message: "meddelande",
  details: "Detaljer",
  subscriptionId: "Prenumerations-ID",
  className: "Klassens namn",
  methodName: "Metodens namn",
  timeGenerated: "Genererad tid",
  appLog: "Logg för appar",
  requestedUser: "Efterfrågad användare",

  //Cost Center user
  //costCenterUser: "Kostnadsställeanvändare",
  costCenterUser: "Anställd manuell post",
  msgSelectCostCenterUser: "Välj kostnadsställeanvändare",
  or: "Eller",
  msgSelectCostCenterUserOrEmployee:
    "Välj kostnadsställeanvändare eller anställd",
  msgSelectEmployee: "Välj anställd",

  //Cost center group permission
  costCenterGroupPermission: "Behörighet för kostnadsställegrupp",
  msgSelectCostCenterGroupAtLeast: "Välj minst en kostnadsställegrupp",

  detail: "Detalj",

  
  //SyncLogSummary
  syncLogSummary: "Synkroniseringslogg Sammanfattning",
  success:"Framgång",
  warnings:"Varningar",
  errors:"Fel",
  criticals:"Kritiker",
  lastSync: "Senaste Synkronisering"

};

export default se;
