export interface SyncLog {
  id: number;
  subscriptionId: string;
  companyId: number;
  requestedAt: string;
  requestedBy: string;
  startedAt: string;
  runStatus: string;
  hasError: boolean;
  errorMessage: string;
  isFullSync: boolean;
  jobRunId: string;
  tryCount: number;
  companyName: string;
  hasAnyError: string;
  forceSyncDate: string;
}

export interface SyncLogDetails {
  id: number;
  logId: number;
  entity: string;
  startedAt: string;
  completedAt: string;
  runStatus: string;
  hasError: boolean;
  errorMessage: string;
  hasAnyError: string;
}

export interface SyncLogGetResponse {
  result: Property;
  errorMessage: any;
  timeGenerated: string;
}

export interface SyncLogDetailsGetResponse {
  result: PropertySyncLogDetails;
  errorMessage: any;
  timeGenerated: string;
}

export interface Property {
  result: SyncLog[];
  count: number;
}

export interface PropertySyncLogDetails {
  result: SyncLogDetails[];
  count: number;
}

export const RunStatus = {
  Pending: 1,
  Running: 2,
  Completed: 3,
  Failed: 4,
};
