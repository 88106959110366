
import agent, { masterServiceBaseURL } from '@/services/agent';
import { defineComponent } from 'vue';
import { ForgetPasswordResponse } from './ForgetPassword.model';
import authService from '@/services/authService';
import router from '@/router/router';
import { API_KEY } from './Constants';


export default defineComponent({
  created() {
    this.classAddRemove();
    agent.setBaseUrl(masterServiceBaseURL);
  },
  data() {
    return {
      email: null,
      isSendEmailLoader: false,
      isSent: false
    }
  },
  mounted() {
    this.email = null;
    this.RedirectTo();
  },
  methods: {
    async onSubmit(event: any) {
      event.preventDefault();

      let post = { email: this.email };

      this.isSendEmailLoader = true;
      this.isSent = false;

      const response = await agent.postAnonymous<ForgetPasswordResponse>(API_KEY.POST, post);
      if(response && response.result){
        this.isSent = true;
      }
      this.isSendEmailLoader = false;
    },
    RedirectTo() {
      const isExpired = authService.isExpired(authService.getToken());
      if (!isExpired) router.push("/");
    },
    classAddRemove() {
      var bodyElement = document.body;
      var sidbarminiClass = bodyElement.classList.contains("sidebar-mini");
      var layoutfixedClass = bodyElement.classList.contains("layout-fixed");
      var registerpageClass = bodyElement.classList.contains("register-page");
      if (sidbarminiClass) bodyElement.classList.remove('sidebar-mini');
      if (layoutfixedClass) bodyElement.classList.remove('layout-fixed');
      if (registerpageClass) bodyElement.classList.remove('register-page');
    }
  }
});
