import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group input-group-sm" }
const _hoisted_2 = { class: "input-group-prepend" }
const _hoisted_3 = { class: "input-group-text" }
const _hoisted_4 = { value: 0 }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('company')), 1)
    ]),
    _createElementVNode("select", {
      class: "form-control",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeCompany($event)))
    }, [
      _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.$t('all')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
        return (_openBlock(), _createElementBlock("option", {
          key: item.id,
          value: item.id
        }, _toDisplayString(item.name), 9, _hoisted_5))
      }), 128))
    ], 32)
  ]))
}