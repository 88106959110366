
import agent, { masterServiceBaseURL } from "@/services/agent";
import authService from "@/services/authService";
import { useDataSyncConfigurationStore } from "@/store/useDataSyncConfigurationStore";
import { useUserStore } from "@/store/useUserStore";
import { defineComponent } from "vue";
import ChangePasswordComponent from "../master/features/ChangePassword/ChangePasswordComponent.vue";
import LoaderComponent from "../molecules/LoaderComponent.vue";
import AppBarComponent from "../organisms/AppBarComponent.vue";
import emitter from "@/services/mitt";
import { UserPage } from "./model";

let _lang: string = "EN";
let _multilang: { se: boolean; en: boolean } = {} as {
  se: boolean;
  en: boolean;
};

export default defineComponent({
  components: {
    AppBarComponent,
    LoaderComponent,
    ChangePasswordComponent,
  },
  setup() {
    const userStore = useUserStore();
    const dataSyncConfigurationStore = useDataSyncConfigurationStore();
    return { userStore, dataSyncConfigurationStore };
  },
  created() {
    agent.setBaseUrl(masterServiceBaseURL);
  },
  mounted() {
    this.$i18n.locale = "en";
    this.multilang = { en: true, se: false };
    this.lang = this.$i18n.locale.toUpperCase();
    this.classAddRemove();
    this.loadUserPage();
    emitter.on('update-permission', () => {
      this.loadUserPage();
    });
  },
  data() {
    return {
      lang: _lang,
      multilang: _multilang,
      data: [] as UserPage[],
    };
  },
  computed: {
    
  },
  methods: {
    async loadUserPage() {
      const user = authService.getJwtUser();
      this.userStore.setLoggedInUser(user);
      if (user) {
        const response = await this.userStore.fetchUserPages(user.id);
        if (response && response.result) {
          this.userStore.setUserPages(response.result);
          this.data = response.result;
          console.log({data:this.data});
        }
      }
    },
    logOut() {
      authService.removeJwtUser();
      this.$router.go(0);
    },
    isHost() {
      return authService.isHost();
    },
    getOrganizationName() {
      return authService.getOrganizationName();
    },
    buildKey(inputString: string) {
      const words = inputString.split(" ");

      const camelCasedWords = words.map((word: any, index: any) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      });

      const camelCasedString = camelCasedWords.join("");
      return camelCasedString;
    },
    languageChange(code: string) {
      this.$i18n.locale = code;
      this.lang = code.toUpperCase();

      this.multilang = { en: false, se: false };
      switch (code.toLowerCase()) {
        case "en":
          this.multilang = { en: true, se: false };
          break;
        case "se":
          this.multilang = { en: false, se: true };
          break;
      }
    },
    hasNotChildPageURL(page: UserPage) {
      return !page.pageUrl && (!page.childs ||  (page.childs && page.childs.length === 0));
    },
    hasPageURLNotChild(page: UserPage) {
      return page.pageUrl && (!page.childs ||  (page.childs && page.childs.length === 0));
    },
    hasNotChild(page: UserPage) {
      return (!page.childs ||  (page.childs && page.childs.length === 0));
    },
    hasChilds(page:UserPage){
      return page && page.childs && page.childs.length>0;
    },
    hasActive(page:UserPage, path:string){
      return path == page.pageUrl ? 'active' : '';
    },
    makeIcon(page:UserPage,cssClass:string="page-icon pt-1"){
      return `${page.pageIcon} ${cssClass}`;
    },
    classAddRemove() {
      var bodyElement = document.body;
      var sidbarminiClass = bodyElement.classList.contains("sidebar-mini");
      var layoutfixedClass = bodyElement.classList.contains("layout-fixed");
      var registerpageClass = bodyElement.classList.contains("register-page");
      var loginpageClass = bodyElement.classList.contains("login-page");
      if (sidbarminiClass) bodyElement.classList.remove("sidebar-mini");
      if (layoutfixedClass) bodyElement.classList.remove("layout-fixed");
      if (registerpageClass) bodyElement.classList.remove("register-page");
      if (loginpageClass) bodyElement.classList.remove("login-page");
      bodyElement.classList.add("sidebar-mini");
      bodyElement.classList.add("layout-fixed");
    },
  },
});
