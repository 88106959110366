
import { defineComponent, onMounted, ref } from 'vue';
import { Company } from '@/components/master/features/Company/Company.model';
import authService from '@/services/authService';
import axios from 'axios';
import { fortnoxServiceBaseURL } from '@/services/agent';

let _data: Company[] = [];

export default defineComponent({
    emits: ['onCompanyChange'],
    setup(props, ctx) {
        const data = ref(_data);

        onMounted(() => {
            loadCompany();
        });


        function loadCompany() {
            axios.post(`${fortnoxServiceBaseURL}v1/company/getcompany`, {}, {
                headers: { Authorization: `Bearer ${authService.getToken()}` }
            }).then((response) => {
                if (response) {
                    data.value = response.data.result.result;
                }
            }).catch(() => { });
        }

        function changeCompany(event: any) {
            ctx.emit('onCompanyChange', event.target.value);
        }

        return {
            props, ctx, data, loadCompany, changeCompany
        }
    }
})
