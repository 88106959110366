import modal from "@/components/common/modalManage";
import ConfirmComponent from "@/components/molecules/ConfirmComponent.vue";
import agent, { powerOfficeServiceBaseURL } from "@/services/agent";
import preLoader from "@/services/preLoader";
import toasterService from "@/services/toasterService";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import {
  CommandColumn,
  ColumnDirective as EColumn,
  ColumnsDirective as EColumns,
  GridComponent as EjsGrid,
  Group,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-vue-grids";
import { defineComponent } from "vue";
import { GridResponse } from "../../common/CommonInterfaces";
import { CompanyInfo, CompanyResponse } from "./Company.model";
import { API_ENDPOINTS } from "./Constants";

export default defineComponent({
  components: {
    "ejs-grid": EjsGrid,
    "e-columns": EColumns,
    "e-column": EColumn,
    ConfirmComponent,
    DatePickerComponent,
  },
  provide: {
    grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group],
  },
  data() {
    return {
      gridData: [] as CompanyInfo[],
      form: {} as CompanyInfo,
      isUpdate: false,
      locale: "",
      searchOptions: {
        fields: ["name", "applicationKey", "clientKey", "subscriptionKey"],
        operator: "contains",
        ignoreCase: true,
      },
      toolbar: ["Search"],
      pageSettings: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        pageCount: 5,
      },
      commands: [
        {
          type: "Edit",
          buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
        },
        // { type: 'ForceSync', buttonOption: { cssClass: 'e-flat force-sync', iconCss: 'e-repeat e-icons' } }
      ],
      forceSync: {
        date: null,
        format: "yyyy-MM-dd",
        today: new Date(),
        companyId: 0,
        type: "full",
      },
    };
  },
  created() {
    agent.setBaseUrl(powerOfficeServiceBaseURL);
    this.gridLocalize();
  },
  mounted() {
    this.loadCompany();
  },

  watch: {
    "$i18n.locale": {
      handler(newVal, oldVal) {
        this.gridLocalize();
      },
      immediate: true,
    },
  },

  methods: {
    async loadCompany() {
      const companyGrid: any = this.$refs.companyGrid;
      if (companyGrid) companyGrid.hideSpinner();
      const response = (await agent.get<GridResponse>(API_ENDPOINTS.GET)) || {};
      if (response.result) {
        this.gridData = response.result.result;
      }
    },

    async upsertCompany(event: any) {
      event.preventDefault();
      const apiEndpoint = this.isUpdate
        ? API_ENDPOINTS.PUT
        : API_ENDPOINTS.POST;
      const method = this.isUpdate
        ? agent.put<CompanyResponse>
        : agent.post<CompanyResponse>;
      const response = (await method(apiEndpoint, this.form)) || {};

      await this.loadCompany();
      this.resetPage();
      this.closeModal();

      if (response?.result?.isCommand) {
        toasterService.success(response.result.message);
      } else {
        toasterService.error(response.result.message);
      }
    },

    gridLocalize() {
      console.log(this.$i18n.locale);

      this.locale = "en-grid";
      if (this.$i18n.locale == "se") {
        setTimeout(() => {
          import(`@/assets/sv.json`).then((module) => {
            console.log(module);

            const localText = module.default;
            this.locale = "sv";
            setCulture("sv");
            L10n.load(localText);
          });
        });
      }
    },

    forceSyncByDate() {
      const params = {
        companyId: this.forceSync.companyId,
        forceSyncDate:
          this.forceSync.type === "full" ? null : this.forceSync.date,
      };
      preLoader.show();
      agent
        .post<any>(`v1/SyncLogMaster/ForceSync`, params)
        .then((response) => {
          if (response.result) {
            modal.Close("force-sync-modal");
            toasterService.success(response.result.message);
            preLoader.hide();
          }
        })
        .catch((e) => {
          preLoader.hide();
          modal.Close("force-sync-modal");
          console.warn(e);
        });
    },

    commandClick: function (args: { commandColumn: any; rowData: any }) {
      this.isUpdate = false;
      const app = this;
      if (args) {
        switch (args.commandColumn.type) {
          case "Edit":
            this.form = args.rowData;
            this.isUpdate = true;
            modal.Open("PageModal");
            break;
          case "ForceSync":
            this.forceSync.companyId = args.rowData.id;
            modal.Open("force-sync-modal");
            break;
          default:
            break;
        }
      }
    },

    openModal() {
      this.resetPage();
      modal.Open("PageModal");
    },

    closeModal() {
      this.resetPage();
      modal.Close("PageModal");
    },

    closeForceSyncModal() {
      modal.Close("force-sync-modal");
    },

    resetPage() {
      this.form = {
        id: 0,
        isActive: false,
      } as CompanyInfo;
      this.isUpdate = false;
    },
  },
});
