
import modal from "@/components/common/modalManage";
import agent, { masterServiceBaseURL } from "@/services/agent";
import authService from "@/services/authService";
import toasterService from "@/services/toasterService";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import {
  CommandColumn,
  ColumnDirective as EColumn,
  ColumnsDirective as EColumns,
  GridComponent as EjsGrid,
  Freeze,
  Group,
  Page,
  Resize,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-vue-grids";
import { DialogUtility } from "@syncfusion/ej2-vue-popups";
import { defineComponent } from "vue";
import SubsriberType from "../../common/SubsriberTypeEnum";
import { Module, ModuleGetResponse } from "../Module/Module.model";
import { Subscription } from "../Subscription/Subscription.model";
import { SubscriptionGetResponse } from "../User/User.model";
import { API_KEY, TABLE_SETTINGS } from "./constants";
import {
  GetParentPageResponse,
  PageForm,
  PageFormGetResponse,
  PageFormResponse,
  Property,
} from "./Page.model";
import emitter from "@/services/mitt";

let Confirmation: any = undefined;

export default defineComponent({
  components: {
    "ejs-grid": EjsGrid,
    "e-columns": EColumns,
    "e-column": EColumn,
    "ejs-dropdownlist": DropDownListComponent,
  },
  provide: {
    grid: [Toolbar, Resize, CommandColumn, Page, Sort, Group, Freeze],
  },
  created() {
    agent.setBaseUrl(masterServiceBaseURL);

    this.gridLocalize();
  },
  data() {
    return {
      listSubscription: [] as Subscription[],
      data: {} as Property,
      form: {} as PageForm,
      isUpdate: false,
      selectedState: null,
      searchOptions: TABLE_SETTINGS.SEARCH_OPTIONS,
      toolbar: TABLE_SETTINGS.TOOLBARS,
      pageSettings: TABLE_SETTINGS.PAGE_SETTINGS,
      commands: TABLE_SETTINGS.COMMANDS,
      module: {
        data: [] as  Module[],
        fields: { text: "displayName", value: "id" },
      },
      parentPage: {
        data: [] as PageForm[],
        fields: { text: "title", value: "id" },
      },
      subscriberType: {
        data: [SubsriberType.Host, SubsriberType.Tenant],
        fields: { text: "name", value: "name" },
      },
      locale: "",
    };
  },
  async mounted() {
    this.resetPage();
    this.dataStateChange(
      this.initState(this.pageSettings.state)
    );
    await this.loadModule();
    await this.loadSubscription();
    await this.loadParentPages();
  },
  unmounted() {
        this.pageSettings.state = {
            skip:TABLE_SETTINGS.PAGE_SETTINGS.state.skip,
            take:TABLE_SETTINGS.PAGE_SETTINGS.state.take,
        } as any
    },
  methods: {
    initState(params:any): any {
      const state = {
        ...params
      };
      return state;
    },
    dataStateChange(state: any) {
      this.pageSettings.state = state;
      this.gridLocalize();
      this.loadPage(state);
    },
    chkIsParentChange() {
      if (this.form.isParent) {
        this.form.parentId = null;
      }
    },
    async loadParentPages(){
      const response = await agent.get<GetParentPageResponse>(
        API_KEY.GET_PARENTS
      );
      if(response && response.result){
        this.parentPage.data = response.result;
      }
    },
    async loadPage(state: any) {
      if (state.action) {
        delete state.action;
      }

      const gridPage: any = this.$refs.gridPage;
      if (gridPage) gridPage.hideSpinner();

      const response = await agent.post<PageFormGetResponse>(
        API_KEY.GET_PAGES,
        state
      );
      if (response && response.result) {
        this.data = response.result;
      }
    },
    async loadModule() {
      const response = await agent.post<ModuleGetResponse>(
        API_KEY.GET_MODULES,
        {}
      );
      if (response && response.result) {
        this.module.data = [
          {
            id: 0,
            displayName: this.$t("root"),
          } as Module,
          ...response.result.result,
        ];
      }
    },
    async loadSubscription() {
      const response = await agent.post<SubscriptionGetResponse>(
        API_KEY.GET_SUBSCRIPTIONS,
        {}
      );
      if (response && response.result) {
        this.listSubscription = response.result.result;
      }
    },
    onUpsert(event: any) {
      event.preventDefault();

      if (this.isUpdate) {
        this.onEdit();
      } else {
        this.onSave();
      }
    },
    async onSave() {
      let post = { ...this.form };
      post.createdBy = authService.getUserEmail();

      if (post.isParent) post.parentId = undefined;

      post.subscriberType = String(post.subscriberType);

      const response = await agent.post<PageFormResponse>(API_KEY.POST, post);
      if (response && response.result) {
        toasterService.success(this.$t("msgSave"));
        this.loadPage(
          this.initState(this.pageSettings.state)
        );
        this.resetPage();
        this.close();
        this.loadParentPages();
        emitter.emit('update-permission',{});
      }
    },
    async onEdit() {
      let put = { ...this.form };

      put.modifiedBy = authService.getUserEmail();

      if (put.isParent) put.parentId = undefined;

      put.subscriberType = String(put.subscriberType);

      const response = await agent.put<PageFormResponse>(API_KEY.PUT, put);
      if (response && response.result) {
        toasterService.success(this.$t("msgUpdate"));
        this.loadPage(
          this.initState(this.pageSettings.state)
        );
        this.resetPage();
        this.close();
        this.loadParentPages();
        emitter.emit('update-permission',{});
      }
    },
    async onDelete(id: number) {
      const response = await agent.delete<PageFormResponse>(`v1/page/${id}`);
      if (response && response.result) {
        toasterService.success(this.$t("msgDelete"));
        this.loadPage(
          this.initState(this.pageSettings.state)
        );
        this.resetPage();
        this.loadParentPages();
        emitter.emit('update-permission',{});
      }
    },
    resetPage() {
      this.form = {} as PageForm;
      this.form.parentId = null;
      this.form.isParent = true;
      this.form.isActive = true;
      this.form.moduleId = 0;
      this.isUpdate = false;
      this.form.subscriberType = SubsriberType.Tenant.name;
    },
    gridLocalize() {
      this.locale = "en-grid";
      if (this.$i18n.locale == "se") {
        setTimeout(() => {
          import(`@/assets/sv.json`).then((module) => {
            const localText = module.default;
            this.locale = "sv";
            setCulture("sv");
            L10n.load(localText);
          });
        });
      }
    },

    commandClick: function (args: { commandColumn: any; rowData: any }) {
      this.isUpdate = false;
      const app = this;
      if (args) {
        switch (args.commandColumn.type) {
          case "Delete":
            this.form.id = args.rowData.id;
            Confirmation = DialogUtility.confirm({
              title: this.$t("deleteConfiramtion"),
              content: this.$t("msgSureWantToDelete"),
              okButton: {
                text: this.$t("ok"),
                click: async function () {
                  Confirmation.hide();
                  app.onDelete(app.form.id);
                },
              },
              cancelButton: { text: this.$t("cancel") },
              showCloseIcon: true,
              closeOnEscape: true,
              zIndex: 10000,
              animationSettings: { effect: "Zoom" },
            });
            break;
          case "Edit":
            this.form = args.rowData;            
            this.isUpdate = true;
            modal.Open("PageModal");
            break;
          default:
            break;
        }
      }
    },
    open() {
      modal.Open("PageModal");
    },
    close() {
      this.resetPage();
      modal.Close("PageModal");
    },
  },
});
