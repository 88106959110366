
import { defineComponent } from 'vue';
import RegisterModalComponent from './RegisterModalComponent.vue';

export default defineComponent({
    components: {
        RegisterModalComponent
    },
    created() {
    },
    data() {
        return {
        }
    },
    mounted() {

    },
    methods: {

    }
});
