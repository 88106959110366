import {
  fortnoxServiceBaseURL,
  powerOfficeServiceBaseURL,
} from "@/services/agent";
import ModuleEnum from "../../common/ModuleEnum";

export default class EndpointResolver {
  constructor() {}

  getMasterLogDetailsEndpoint(moduleName: string): string {
    let endpoint = "";

    if (moduleName == ModuleEnum.Fortnox.id) {
      // Fortnox
      endpoint = `${fortnoxServiceBaseURL}v1/SyncLogDetails/GetSyncLogDetails`;
    }

    else if (moduleName == ModuleEnum.PowerOffice.id) {
      endpoint = `${powerOfficeServiceBaseURL}v1/SyncLogDetails/GetSyncLogDetails`;
    }

    return endpoint;
  }

  getMasterLogEndpoint(moduleName: string): string {
    let endpoint = "";

    if (moduleName == ModuleEnum.Fortnox.id) {
      // Fortnox
      endpoint = `${fortnoxServiceBaseURL}v1/SyncLogMaster/GetSyncLogMaster`;
    }

    else if (moduleName == ModuleEnum.PowerOffice.id) {
      endpoint = `${powerOfficeServiceBaseURL}v1/SyncLogMaster/GetSyncLogMaster`;
    }

    return endpoint;
  }
}
