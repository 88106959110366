
import { defineComponent, ref } from 'vue';
import modal from '../common/modalManage';

export default defineComponent({
  props: ['title', 'type','isSubscription','subscriptionDeleteTitle'],
  emits: ['yes', 'no'],
  setup(props, ctx) {

    const data = ref({
      isSubsDelete:false
    });

    function close() {
      modal.Close('modal-confirm');
    } 
    return {
      props, ctx, close,data
    }
  },
});

