import { UserPage, UserPageGetResponse } from "@/components/common/model";
import agent from "@/services/agent";
import { defineStore } from "pinia";
import { LoginDTO } from "@/services/models/login";

export const useUserStore = defineStore({
    id: "user",
    state: () => ({
        userPages: [] as UserPage[],
        userInfo: {} as LoginDTO,
    }),
    actions: {
        setUserPages(userPages: UserPage[]) {
            this.userPages = userPages;
        },
        async fetchUserPages(userId:number){
            return await agent.get<UserPageGetResponse>(`v1/page/userpage/${userId}`);
        },
        setLoggedInUser(userInfo: LoginDTO) {
            this.userInfo = userInfo;
        }
    },
});