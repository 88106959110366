
import agent, { masterServiceBaseURL } from '@/services/agent';
import { defineComponent } from 'vue';
import { Login, LoginResponse } from './Login.model';
import authService from '@/services/authService';
import router from '@/router/router';
import { LoginDTO } from '@/services/models/login';
import { useUserStore } from '@/store/useUserStore';

let _loginForm: Login = {} as Login;

export default defineComponent({
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      form: _loginForm,
      isLoginLoader: false,
    }
  },
  created() {
    this.classAddRemove();
    agent.setBaseUrl(masterServiceBaseURL);
  },
  mounted() {
    this.form = {} as Login;
    this.RedirectTo();
  },
  methods: {
    onLogin(event:any) {
      event.preventDefault();
      
      let post = { ...this.form };

      this.isLoginLoader = true;

      agent.postAnonymous<LoginResponse>(`v1/account/login`, post).then((response) => {
        authService.setJwtUser(response.result);
        this.isLoginLoader = false;
        this.$router.push("/");
        this.$router.go(0);
      })
      .catch(()=>
      {
        this.isLoginLoader = false;
      });
    },
    loadUserPage(user: LoginDTO) {
      authService.setJwtUser(user);
    },
    RedirectTo() {
      const isExpired = authService.isExpired(authService.getToken());
      if (!isExpired) router.push("/");
    },
    classAddRemove() {
      var bodyElement = document.body;
      var sidbarminiClass = bodyElement.classList.contains("sidebar-mini");
      var layoutfixedClass = bodyElement.classList.contains("layout-fixed");
      var registerpageClass = bodyElement.classList.contains("register-page");
      if (sidbarminiClass) bodyElement.classList.remove('sidebar-mini');
      if (layoutfixedClass) bodyElement.classList.remove('layout-fixed');
      if (registerpageClass) bodyElement.classList.remove('register-page');
      //bodyElement.classList.add('register-page');
    }
  }
});
