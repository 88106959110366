import { PasswordValidatorVM } from "./models/passwordValidatorVM";

class PasswordValidator {
    ValidatePassword(password: string): PasswordValidatorVM {
      

      let result : PasswordValidatorVM = {
        isValid: true,
        message: ""
       };   

      if(password.length == 0){
        result.isValid = false;
        result.message = "msgEnterAPassword"; 
        return result;
      }

      // Requires at least one number, one lowercase letter, one uppercase letter, and at least six characters
      const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
      result.isValid =  re.test(password);

      if(result.isValid)
      {
        result.isValid = true;
        result.message = "" 
        return result;
      }
      else
      {
        result.isValid = false;
        result.message = "msgInvalidPassword";

        return result;

      }

    }
}



  
export default new PasswordValidator();