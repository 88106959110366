
import { defineComponent, onMounted, ref } from 'vue';
import { Subscription, SubscriptionGetResponse } from './Subscription.model';
import agent from '@/services/agent';

let _data:Subscription[]=[];

export default defineComponent({
    emits:['onSubscription'],
    setup(props,ctx){
        const data=ref(_data);

        onMounted(()=>{
            loadSubscription();
        });

        function loadSubscription() {
            agent.post<SubscriptionGetResponse>(`v1/subscription/getsubscription`,{}).then((response) => {
                if (response.result) {
                     data.value = response.result.result;
                }
            }).catch(() => { });
        }

        function changeSubscription(event:any){
            ctx.emit('onSubscription',event.target.value);
        }

        return {
            props,ctx,data,loadSubscription,changeSubscription
        }
    }
})
